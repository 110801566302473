import React from 'react';
import './AboutSection.css';
import { Fade } from 'react-awesome-reveal';
import photoBW from '../images/profile-bw.jpeg';
import photoCOLOR from '../images/profile-colored.jpeg';

const AboutSection = ({reference}) => {
    return (
        <div ref={reference} className='aboutsection'>
            <Fade triggerOnce={true}>
                <h1>ABOUT</h1>
            </Fade>
            <div className='about-flex-container'>
                <Fade cascade delay={500} triggerOnce={true}>
                    <div className='about-flex-image'>
                        <img src={photoBW}
                        onMouseOver={target=>(target.currentTarget.src=photoCOLOR)}
                        onMouseOut={target=>(target.currentTarget.src=photoBW)}
                        alt='Ana Teruya'
                        />
                    </div>
                    <div className='about-flex-bio'>
                        <p>
                            Hi there - my name is Ana and I'm a developer based out of California. 
                            I grew up in an artistic and tech-savy environment, 
                            so I quickly developed a fascination towards both art and technology. These 
                            combined interests influenced me to later pursue a degree in UI/UX design, and eventually a 
                            degree in computer science.
                            <br></br>
                            <br></br>
                            I primarily focus on developing web and mobile applications, but I've also worked 
                            with site management platforms such as Shopify and Wordpress. Please feel free to 
                            explore the projects I've worked on.
                        </p>
                        <Fade delay={1500} triggerOnce={true}>
                            <h3>SKILLS</h3>
                        </Fade>
                        <div className='about-flex-container' id='about-skills'>
                            <div className='about-flex-list'>
                                <ul>
                                    <Fade cascade delay={1750} triggerOnce={true}>
                                        <li>Python/Flask</li>
                                        <li>Flutter/Dart</li>
                                        <li>React</li>
                                        <li>Node.js</li>
                                    </Fade>
                                </ul>
                            </div>
                            <div className='about-flex-list'>
                                <ul>
                                    <Fade cascade delay={1750} triggerOnce={true}>
                                        <li>MongoDB</li>
                                        <li>MySQL</li>
                                        <li>Google Cloud Platform</li>
                                        <li>Heroku</li>
                                    </Fade>
                                </ul>      
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
}

export default AboutSection;