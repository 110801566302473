import React from 'react';
import './HomeSection.css';
import { Fade } from 'react-awesome-reveal';

const HomeSection = ({reference}) => {
    return (
        <div ref={reference} className='homesection'>
            <div id='homesection-text'>
                <Fade cascade triggerOnce={true}>
                    <h1>Welcome.</h1>
                </Fade>
                <Fade cascade delay={1000} triggerOnce={true}>
                    <p>I'm Ana Teruya - a Full Stack Developer.</p>
                </Fade>
            </div>
        </div>
    );
}

export default HomeSection;