import React, { useRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import HomeSection from './components/content/HomeSection';
import AboutSection from './components/content/AboutSection';
import ProjectSection from './components/content/ProjectSection';
import ContactSection from './components/content/ContactSection';
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import Recolor from './components/icon-adjustment/Recolor';
import resume from './components/pdfs/ana_teruya_resume.pdf';

const App = () =>   {
    var encodedEmail = 'dGVydXlhYW5hQGdtYWlsLmNvbQ==';
    // Home section scroll behavior:
    const homeRef = useRef(null);
    const handleHomeClick = () => {
        homeRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    // About section scroll behavior:
    const aboutRef = useRef(null);
    const handleAboutClick = () => {
        aboutRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    // Project section scroll behavior:
    const projectRef = useRef(null);
    const handleProjectClick = () => {
        projectRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    // Contact section scroll behavior:
    const contactRef = useRef(null);
    const handleContactClick = () => {
        contactRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    return (
        <>
            <HomeSection reference={homeRef}/>
            <AboutSection reference={aboutRef}/>
            <ProjectSection reference={projectRef}/>
            <ContactSection reference={contactRef}/>
            <div className='headernav'>
                <ul>
                    <Fade delay={1750} direction='left' triggerOnce={true}>
                        <li id='icon'>
                            <button onClick={handleHomeClick}>&#123;ADT.&#125;</button>
                        </li>
                    </Fade>
                    <Fade cascade delay={1750} direction='right' triggerOnce={true}>
                        <li className='resumebutton'>
                            <a href={resume} target = "_blank" rel="noopener noreferrer">RESUME</a>
                        </li>
                        <li className='sectionbutton'>
                            <button onClick={handleContactClick}>CONTACT</button>
                        </li>
                        <li className='sectionbutton'>
                            <button onClick={handleProjectClick}>PROJECTS</button>
                        </li>
                        <li className='sectionbutton'>
                            <button onClick={handleAboutClick}>ABOUT</button>
                        </li>
                        <li className='sectionbutton'>
                            <button onClick={handleHomeClick}>HOME</button>         
                        </li>
                    </Fade>
                </ul>
            </div>
            <div className='footernav'>
                <ul>
                    <Fade delay={1750} direction='left' triggerOnce={true}>
                        <li id='email'><a href={'mailto:' + encodedEmail}>TERUYAANA@GMAIL.COM</a></li>
                    </Fade>
                    <Fade delay={1750} cascade direction='right' triggerOnce={true}>
                        <li className='icons'>
                            <a href='https://www.linkedin.com/in/ateruya/' target="_blank" rel="noopener noreferrer">
                                <Recolor>
                                    <BsLinkedin 
                                    onMouseOver={({target})=>{target.style.color="grey"; target.style.transitionDuration='0.5s'}}
                                    onMouseOut={({target})=>target.style.color="black"}
                                    onMouseDownCapture={({target})=>{target.style.color="red"; target.style.transitionDuration='0.2s'}}
                                    />
                                </Recolor>
                            </a>
                        </li>
                        <li className='icons'>
                            <a href='https://github.com/adteruya' target="_blank" rel="noopener noreferrer">
                                <Recolor>
                                    <BsGithub 
                                    onMouseOver={({target})=>{target.style.color="grey"; target.style.transitionDuration='0.5s'}}
                                    onMouseOut={({target})=>target.style.color="black"}
                                    onMouseDownCapture={({target})=>{target.style.color="red"; target.style.transitionDuration='0.2s'}}
                                    />
                                </Recolor>
                            </a>
                        </li>
                    </Fade>
                </ul>
            </div>
        </>
    );
}

export default App;