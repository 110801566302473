import React from "react";
import { IconContext } from 'react-icons/lib';

export default function Recolor({ children })   {
    return (
        <>
            <IconContext.Provider value={{ color: "black", size: "1.5em"}}>
                {children}
            </IconContext.Provider>
        </>
    );
}