import React from 'react';
import './ProjectSection.css';
import { Fade } from 'react-awesome-reveal';
import laBW from '../images/language-archive-bw.png';
import laC from '../images/language-archive-colored.png';
import ftBW from '../images/focus-timer-bw.png';
import ftC from '../images/focus-timer-colored.png';

const ProjectSection = ({reference}) => {
    return (   
        <div ref={reference} className='projectsection'>
            <Fade triggerOnce={true}>
                <h1>PROJECTS</h1>
            </Fade>
            <div className='project-flex-container'>
                <Fade cascade delay={500} triggerOnce={true}>
                    <div className='project-flex-tile'>
                        <ul>
                            <li><p>Google Cloud Platform</p></li>
                            <li><p>Dart</p></li>
                            <li><p>Flutter</p></li>
                        </ul>
                        {/** Image will be clickable */}
                        <div className='project-image'>
                            <img src={laBW} 
                            onMouseOver={target=>(target.currentTarget.src=laC)}
                            onMouseOut={target=>(target.currentTarget.src=laBW)}
                            alt='Language Archive App'
                            />
                        </div>
                        <h3>Language Archive</h3>
                        <div className='project-description'>
                            <p>
                                A crowd-sourced language learning app for the Android OS. Learn a 
                                new language by testing yourself with user translated scenarios. 
                                Each scenario features speech-to-text input and audio playback, 
                                allowing you to test your speaking skills and compare them to 
                                native speakers. If you are a fluent speaker, optionally contribute 
                                to the app's language database by providing scenario translations 
                                and audio recordings.
                            </p>
                        </div>
                        {/** Opens project GitHub page */}
                        <a href='https://github.com/NathanaelMartinez/Language-Archive-Language-Learning' target="_blank" rel="noopener noreferrer">VIEW</a>
                    </div>
                    <div className='project-flex-tile'>
                        <ul>
                            <li><p>Heroku</p></li>
                            <li><p>Flask</p></li>
                            <li><p>Python</p></li>
                        </ul>
                        {/** Image will be clickable */}
                        <div className='project-image'>
                            <img src={ftBW} 
                            onMouseOver={target=>(target.currentTarget.src=ftC)}
                            onMouseOut={target=>(target.currentTarget.src=ftBW)}
                            alt='Focus Timer App'
                            />
                        </div>
                        <h3>Focus Timer</h3>
                        <div className='project-description'>
                            <p>
                                A web app based off of Francesco Cirillo's Pomodoro Technique. Choose 
                                the amount of time you would like to focus and rest, then begin the app's
                                countdown timer. Focus on your studies, work, or hobby for the chosen 
                                amount of time, then smoothly transition into the break timer for a 
                                needed breather - both focus and rest timers can be skipped or paused 
                                when needed. Includes a built in to-do list for task tracking!
                            </p>
                        </div>
                        {/** Opens project GitHub page */}
                        <a href='https://github.com/adteruya/Focus-Timer' target="_blank" rel="noopener noreferrer">VIEW</a>
                    </div>
                </Fade>
            </div>
        </div>
    );
}

export default ProjectSection;