import React from 'react';
import './ContactSection.css';
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import { Fade } from 'react-awesome-reveal';
import ResizeRecolor from '../icon-adjustment/ResizeRecolor';
import ResizeRecolorEmail from '../icon-adjustment/ResizeRecolorEmail';

const ContactSection = ({reference}) => {
    var encodedEmail = 'dGVydXlhYW5hQGdtYWlsLmNvbQ==';
    return (
        <div ref={reference} className='contactsection'>
            <Fade triggerOnce={true}>
                <h1>CONTACT</h1>
            </Fade>
            <div>
                <Fade delay={500} triggerOnce={true}>
                    <p>
                        I'm currently open and looking for new opportunities! <br></br>If you're interested in connecting, 
                        you can message me on one of the following platforms:
                    </p>
                </Fade>
                <div className='contacticons'>
                    <Fade cascade delay={1000} triggerOnce={true}>
                        <div className='icons'>
                            <a href={'mailto:' + encodedEmail}>
                                <ResizeRecolorEmail>
                                    <MdOutlineEmail
                                    onMouseOver={({target})=>{target.style.color="grey"; target.style.transitionDuration='0.5s'}}
                                    onMouseOut={({target})=>target.style.color="black"}
                                    onMouseDownCapture={({target})=>{target.style.color="red"; target.style.transitionDuration='0.2s'}}
                                    />
                                </ResizeRecolorEmail>
                            </a>
                        </div>
                        <div className='icons'>
                            <a href='https://github.com/adteruya' target="_blank" rel="noopener noreferrer">
                                <ResizeRecolor>
                                    <BsGithub 
                                    onMouseOver={({target})=>{target.style.color="grey"; target.style.transitionDuration='0.5s'}}
                                    onMouseOut={({target})=>target.style.color="black"}
                                    onMouseDownCapture={({target})=>{target.style.color="red"; target.style.transitionDuration='0.2s'}}
                                    />
                                </ResizeRecolor>
                            </a>
                        </div>
                        <div className='icons'>
                            <a href='https://www.linkedin.com/in/ateruya/' target="_blank" rel="noopener noreferrer">
                                <ResizeRecolor>
                                    <BsLinkedin
                                    onMouseOver={({target})=>{target.style.color="grey"; target.style.transitionDuration='0.5s'}}
                                    onMouseOut={({target})=>target.style.color="black"}
                                    onMouseDownCapture={({target})=>{target.style.color="red"; target.style.transitionDuration='0.2s'}}
                                    />
                                </ResizeRecolor>
                            </a>
                        </div>
                    </Fade>
                </div>
                <Fade delay={2250} triggerOnce={true}>
                    <p id='contact-second-header'>Looking forward to meeting you!</p>
                </Fade>
            </div>
            <div id='credits'>
                <p>Designed & Built by Ana Teruya</p>
            </div>
        </div>
    );
}

export default ContactSection;